import { Autocomplete, debounce, Drawer, IconButton, TextField } from "@mui/material"
import styled from "styled-components"
import { ReactComponent as TagCrossIcon } from "../../assets/images/tag-cross-icon.svg";
import AppButton from "../../AppComponents/AppButton";
import { AppForm, AppFormDatePicker, AppFormField, AppFormSelectField, AppFormTextArea, AppFormTimePickerMui, AppSubmitButton } from "../../AppComponents/Forms";
import { useCallback, useEffect, useState } from "react";
import { TaskType } from "../Leads/contactDetailedComponents/AddContactStyles";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactBasicInfo, getContactTaskOutcome, getContactTaskPriority, getContactTaskType } from "../../Store/Slices/Contacts/contacts.selector";
import { DashboardNetworkService } from "../../Store/Slices/DashboardRedux/DashboardNetworkService";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import AppFormCheckboxField from "../../AppComponents/Forms/AppFormCheckboxField";
import { CreateTaskSchema, getCreateTaskBody } from "./validation";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useParams } from "react-router-dom";
import { AppConfig } from "../../Config/app-config";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { HocBackdropLoader } from "../../HOC/HocBackdropLoader";
import logo from "../../assets/images/logo.svg";

let timer: NodeJS.Timeout;
interface Props extends LoaderComponentProps {
    taskId: any;
    onClose: any;
    getAllTaskList: any;
    open: any
    setDateFilter: any;
}
const Task = (props: Props) => {
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const params = useParams();
    const { contact_id, id } = params;
    const userData: any = AppConfig.getUserData("loggedInUserData")?.data?.user;
    const TaskOutcome = useAppSelector(getContactTaskOutcome);
    const TaskPriority = useAppSelector(getContactTaskPriority);
    const basicInformation: any = useAppSelector(ContactBasicInfo);
    const TaskType = useAppSelector(getContactTaskType);
    const [markAsCompleted, setMarkAsCompleted] = useState<boolean>(false)
    const [agentListForAllTask, setAgentListForAllTask] = useState([])
    const [agentList, setAgentList] = useState([])
    const [relatedTo, setRelatedTo] = useState([])
    const [showContactDetails, setShowContactDetails] = useState(false)
    const [inputValue, setInputValue] = useState("");
    const [selectRelatedToValue, setSelectRelatedToValue] = useState<any>({})
    const [statusRelatedTo, setStatusRelatedTo] = useState(false)
    const [noDataFound, setNoDataFound] = useState(false)
    const [open, setOpen] = useState(false); // Explicitly control open state
    const [localValues, setLocalValues] = useState<any>({
        //For Create Task
        create_task_title: "",
        create_task_description: "",
        create_task_type: "",
        create_task_date: new Date(new Date().setDate(new Date().getDate() + 1)),
        create_task_time: "",
        create_task_priority: "",
        create_task_outcome: "",
        create_task_owner: "",
        create_task_related_to: "",
        create_task_related_to_Details: {},
        mark_as_completed: false,
        task_completed_date: "",
        task_completed_time: "",
    });
    useEffect(() => {
        setShowContactDetails(false)
        setSelectRelatedToValue({})
        setMarkAsCompleted(false)
        setStatusRelatedTo(false)
        dispatch(ContactsActions.getContactTaskDetailsOptions({}))
        DashboardNetworkService.getAgentList().then((res: any) => {
            setAgentList(res?.data?.agent_list || []);
            setAgentListForAllTask(res?.data?.agent_list || [])
        });
    }, [])
    const getTomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Increment by 1 to get tomorrow's date
        return tomorrow;
    };
    useEffect(() => {
        if (props?.taskId) {
            props.setLoading(true)
            dispatch(ContactsActions.getSingleTask(props?.taskId))
                .then((response: any) => {
                    if (response?.payload?.data && response?.payload?.data?.status) {
                        setLocalValues({
                            ...localValues,
                            create_task_title: response?.payload?.data?.data?.task_title,
                            create_task_description: response?.payload?.data?.data?.task_description,
                            create_task_type: response?.payload?.data?.data?.task_type,
                            create_task_date: response?.payload?.data?.data?.task_date,
                            create_task_time: new Date(response?.payload?.data?.data?.task_date),
                            create_task_priority: response?.payload?.data?.data?.task_priority,
                            create_task_owner: response?.payload?.data?.data?.task_owner?.id,
                            create_task_related_to_Details: {
                                "full_name": response?.payload?.data?.data?.related_to?.first_name && response?.payload?.data?.data?.related_to?.last_name ? response?.payload?.data?.data?.related_to?.first_name + " " + response?.payload?.data?.data?.related_to?.last_name : response?.payload?.data?.data?.related_to?.first_name,
                                "id": response?.payload?.data?.data?.related_to?.id
                            },
                            mark_as_completed: response?.payload?.data?.data?.mark_as_completed,
                            task_completed_date: response?.payload?.data?.data?.completed_date ? response?.payload?.data?.data?.completed_date : "",
                            task_completed_time: response?.payload?.data?.data?.completed_date ? new Date(response?.payload?.data?.data?.completed_date) : "",
                            create_task_outcome: response?.payload?.data?.data?.task_outcome
                        })
                        setMarkAsCompleted(response?.payload?.data?.data?.completed_date)
                        setSelectRelatedToValue({
                            "full_name": response?.payload?.data?.data?.related_to?.first_name && response?.payload?.data?.data?.related_to?.last_name ? response?.payload?.data?.data?.related_to?.first_name + " " + response?.payload?.data?.data?.related_to?.last_name : response?.payload?.data?.data?.related_to?.first_name,
                            "id": response?.payload?.data?.data?.related_to?.id,
                            "mobile_phone_number": response?.payload?.data?.data?.related_to?.mobile_phone_number,
                            "email": response?.payload?.data?.data?.related_to?.email
                        })
                        props.setLoading(false)
                    } else {
                        showAlert("Task not found", "error");
                        props?.onClose()
                        props.setLoading(false)
                    }
                }).catch((error: any) => {
                    props.setLoading(false)
                    console.error(error);
                });
        } else {
            setMarkAsCompleted(false)
            setSelectRelatedToValue({})
            setLocalValues({
                ...localValues,
                create_task_title: "",
                create_task_description: "",
                create_task_type: "",
                create_task_date: getTomorrowDate(),
                create_task_time: "",
                create_task_priority: "",
                create_task_related_to_Details: {
                },
                mark_as_completed: false,
                task_completed_date: "",
                task_completed_time: "",
                create_task_outcome: "",
                create_task_owner: userData?.id
            })
        }
        if (basicInformation?.general_information && contact_id) {
            setSelectRelatedToValue({
                "full_name": basicInformation?.general_information?.first_name && basicInformation?.general_information?.last_name ? basicInformation?.general_information?.first_name + " " + basicInformation?.general_information?.last_name : basicInformation?.general_information?.first_name,
                "id": contact_id
            })
        } else {
            setSelectRelatedToValue({})
        }
    }, [props?.taskId])
    const handleSubmit = async (values: any, SubmitProps: any) => {
        // if (selectRelatedToValue?.id) {

        values['selectRelatedToValue'] = selectRelatedToValue
        let payload: any = getCreateTaskBody(values)
        if (props?.taskId) {
            props.setLoading(true)
            payload['task_id'] = props?.taskId
            dispatch(ContactsActions.updateTask(payload))
                .then((response: any) => {
                    if (response?.payload?.data?.status) {
                        showAlert("Update Task Successfully", "success");
                        props?.onClose()
                        props?.getAllTaskList()
                        props.setLoading(false)
                        props?.setDateFilter("all")
                    } else {
                        props.setLoading(false)
                        showAlert(response?.payload?.data?.data, "error");
                    }

                }).catch((error: any) => {
                    props.setLoading(false)
                    console.error(error);
                });
            if (values?.selectRelatedToValue?.id) {
                let data = {
                    contact_id: values?.selectRelatedToValue?.id,
                    context: values?.create_task_note,
                };
                dispatch(ContactsActions.addNoteAsPerId(data))
                    .then((response: any) => {

                    })
                    .catch((error: any) => {
                        // setPaginationData({ ...paginationData, pageReady: false });
                    })
                    .finally(() => {
                    });
            }
        } else {
            props.setLoading(true)
            dispatch(ContactsActions.createTask(payload))
                .then((response: any) => {
                    if (response?.payload?.data?.status) {
                        showAlert("Create Task Successfully", "success");
                        props?.onClose()
                        props?.getAllTaskList()
                        props.setLoading(false)
                        props?.setDateFilter("all")
                    } else {
                        props.setLoading(false)
                        showAlert(response?.payload?.data?.data, "error");
                    }

                }).catch((error: any) => {
                    props.setLoading(false)
                    console.error(error);
                });
            if (values?.selectRelatedToValue?.id) {
                let data = {
                    contact_id: values?.selectRelatedToValue?.id,
                    context: values?.create_task_note,
                };
                dispatch(ContactsActions.addNoteAsPerId(data))
                    .then((response: any) => {

                    })
                    .catch((error: any) => {
                        // setPaginationData({ ...paginationData, pageReady: false });
                    })
                    .finally(() => {
                    });
            }
        }
        // } else {
        //     showAlert("Please Select Related To", "error");
        // }
    }
    const handleSelectSearchForAutoComplete = useCallback(debounce((value?: any) => {
        if (value.length < 3) return; // Only trigger API call if input has 3 or more characters
        setStatusRelatedTo(true)
        if (value !== selectRelatedToValue?.full_name) {
            dispatch(ContactsActions.getContectList(value))
                .then((response: any) => {
                    if (response?.payload?.data?.data?.length !== 0 && value !== response?.payload?.data?.data?.[0]?.full_name) {
                        setRelatedTo(response?.payload?.data?.data);
                        setStatusRelatedTo(false)
                        setNoDataFound(false)
                        setOpen(true); // Keep the dropdown open after receiving API response
                    } else {
                        setNoDataFound(true)
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
        }
    }, 900), []); // Memoize the debounce function with an empty dependency array
    const handleClear = () => {
        setLocalValues({
            ...localValues,
            create_task_related_to: "",
            create_task_related_to_Details: {},
        });
        setSelectRelatedToValue({})
        setStatusRelatedTo(false)
        setNoDataFound(false)
        setShowContactDetails(false)
    }
    return (
        <Drawer
            anchor={"right"}
            open={props?.open}
            onClose={props?.onClose}
            sx={{
                "& .MuiPaper-root": {
                    width: "60%",
                    minWidth: "600px",
                },
            }}
        >
            <CreateTaskWapper>
                <div className="mark-complete">
                    <div className="popup-header">
                        <div className="mark-complete-text">{props?.taskId ? "Edit task" : "Create new task"}</div>
                    </div>
                    <div className="popup-heading-icon">
                        <IconButton onClick={props?.onClose}>
                            <TagCrossIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="create-task-body">
                    <AppForm initialValues={localValues} validationSchema={CreateTaskSchema} onSubmit={handleSubmit}>
                        <div className="body">
                            <div className="left">
                                <div className="title input">
                                    <AppFormField
                                        label="Task Title"
                                        name="create_task_title"
                                        className="title input"
                                        requiredSign={true}
                                        placeholder="Enter the task title "
                                        readOnly={localValues?.mark_as_completed}
                                    />
                                </div>
                                <div className="title input">
                                    <AppFormTextArea
                                        label="Description"
                                        name="create_task_description"
                                        rows="4"
                                        placeholder="Enter details about the task..."
                                        divStyle={{ height: "150px" }}
                                        readOnly={localValues?.mark_as_completed}
                                    />
                                </div>
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <div className="title input">
                                        <AppFormSelectField
                                            menuPosition="fixed"
                                            popOverMaxWidth={"10%"}
                                            label="Task type"
                                            name="create_task_type"
                                            defaultText="Select an Task Type"
                                            placeholder="Select an Task Type"
                                            Options={TaskType}
                                            requiredSign={true}
                                            displayLabel={"label"}
                                            valueKey="name"
                                            disabled={localValues?.mark_as_completed}
                                        />
                                    </div>
                                    <AppFormDatePicker disabled={localValues?.mark_as_completed} requiredSign={true} name={"create_task_date"} label="Date" />
                                    <AppFormTimePickerMui
                                        name={"create_task_time"}
                                        label="Time"
                                        utcTime={true}
                                        disabled={localValues?.mark_as_completed}
                                        requiredSign={true}
                                        divStyle={{ marginRight: "21px" }}
                                    />
                                </div>
                                <div className="title input" style={{ display: "flex" }}>
                                    <AppFormSelectField
                                        label="Priority"
                                        name="create_task_priority"
                                        defaultText="Select an priority"
                                        placeholder="Select an priority"
                                        Options={TaskPriority}
                                        requiredSign={true}
                                        displayLabel={"label"}
                                        divStyle={{ width: "34%" }}
                                        popOverMaxWidth={"10%"}
                                        disabled={localValues?.mark_as_completed}
                                    />
                                    {markAsCompleted ? <div className="title input" style={{ width: "100%" }}>
                                        <AppFormSelectField
                                            label="Outcome"
                                            popOverMaxWidth={"10%"}
                                            name="create_task_outcome"
                                            defaultText="Select an outcome"
                                            placeholder="Select an outcome"
                                            Options={TaskOutcome}
                                            displayLabel={"label"}
                                            enableSearch={true}
                                            requiredSign={true}
                                            divStyle={{
                                                marginLeft: "13px",
                                                width: "470px"
                                            }}
                                            disabled={localValues?.mark_as_completed}
                                        />
                                    </div> : null}
                                </div>
                                <div className="title input" style={{ marginTop: "3px" }}>
                                    <AppFormTextArea
                                        label="Note"
                                        name="create_task_note"
                                        rows="4"
                                        placeholder="Enter details note..."
                                        divStyle={{ height: "150px" }}
                                        readOnly={localValues?.mark_as_completed}
                                    />
                                </div>
                            </div>
                            <div className="right">
                                <div className="title input" style={{ marginLeft: "15px" }}>
                                    <AppFormSelectField
                                        label="Owner"
                                        name="create_task_owner"
                                        defaultText="Select an owner"
                                        placeholder="Select an owner"
                                        Options={agentListForAllTask}
                                        requiredSign={true}
                                        displayLabel={"name"}
                                        enableSearch={true}
                                        popOverMaxWidth={"10%"}
                                        disabled={localValues?.mark_as_completed}
                                    />
                                </div>
                                <div className="title input" style={{ marginLeft: "15px" }}>
                                    <div className="bodyTitle" style={{ color: "#9d8d8d", marginBottom: "12px" }}>
                                        Related To
                                    </div>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        open={open} // Control the open state of the dropdown
                                        onOpen={() => setOpen(true)} // Open the dropdown while interacting
                                        onClose={() => setOpen(false)} // Close dropdown on user action
                                        options={relatedTo} // Options array
                                        value={""}
                                        defaultValue={""}
                                        getOptionLabel={(option: any) => option.full_name || ""} // Display full_name
                                        inputValue={inputValue} // Keep input controlled
                                        noOptionsText={statusRelatedTo ? relatedTo?.length === 0 && noDataFound ? "No Data Found" : "Loading...." : "Type to search"} // Custom message when no options
                                        onInputChange={(event: any, newInputValue: string) => {
                                            clearTimeout(timer); // Clear the previous debounce
                                            setInputValue(newInputValue); // Set input value from the input change
                                            if (newInputValue && newInputValue !== selectRelatedToValue?.full_name) {
                                                handleSelectSearchForAutoComplete(newInputValue); // Trigger search API call
                                            } else if (!newInputValue) {
                                                handleClear(); // Clear options when input is cleared
                                            }
                                        }}
                                        disabled={localValues?.mark_as_completed}
                                        style={{
                                            border: "1px solid lightgray",
                                            borderRadius: "5px",
                                            backgroundColor: "rgb(245, 246, 248)",
                                            height: "42px",
                                        }}
                                        onChange={(event: any, newValue: any) => {
                                            if (newValue === null) {
                                                handleClear(); // Handle the clearing of selected value
                                            } else {
                                                setInputValue(""); // Clear the input value after selecting
                                                setSelectRelatedToValue(newValue); // Set the selected value
                                            }
                                            setRelatedTo([]); // Reset options after selection or clearing
                                            setOpen(false); // Close the dropdown
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                onClick={() => { setStatusRelatedTo(false); setShowContactDetails(false) }}
                                                {...params}
                                                placeholder="Select contact from list"
                                                sx={{
                                                    width: "340px", // Custom input width
                                                    position: "relative",
                                                    height: "40px",
                                                    zIndex: 1,
                                                    "& .MuiInputBase-input": { height: "7px" },
                                                    "& .MuiOutlinedInput-root": {
                                                        width: "110%",
                                                        "& fieldset": {
                                                            borderColor: "lightgray",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "gray", // Hover effect
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "blue", // Focus effect
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    {selectRelatedToValue?.full_name ? (
                                        <>
                                            <div className="contact_info">
                                                <a style={{ color: "rgb(69, 57, 160)", cursor: "pointer", marginTop: "2px" }} onClick={() => window.open(`/contacts/view/${selectRelatedToValue?.id}/basic-information`, '_blank')} >
                                                    {selectRelatedToValue?.full_name}

                                                </a>
                                                <div>
                                                    {localValues?.mark_as_completed ? null : <IconButton style={{ paddingTop: "5px" }} onClick={() => handleClear()}>
                                                        <TagCrossIcon />
                                                    </IconButton>}
                                                </div>
                                            </div>
                                            <AppButton style={{ margin: '17px' }} onClick={() => setShowContactDetails(!showContactDetails)} variant="grey">
                                                view details
                                            </AppButton>

                                        </>
                                    ) : null}
                                </div>
                                {showContactDetails ? <div style={{ margin: '0px 19px', display: "inline-grid" }}>
                                    <a style={{ opacity: "50%", marginBottom: '5px' }}>Email</a>
                                    <a>{selectRelatedToValue?.email || "-"}</a>
                                    <a style={{ opacity: "50%", marginBottom: '5px', marginTop: '5px' }}>Mobile</a>
                                    <a>{selectRelatedToValue?.mobile_phone_number || "-"}</a>
                                </div> : null}
                                <div className="title input" style={{ marginLeft: "9px", marginTop: "12px" }}>
                                    {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={localValues?.mark_as_completed}
                                                />
                                            }
                                            label="Mark as completed"
                                            name="mark_as_completed"
                                        /> */}
                                    <AppFormCheckboxField
                                        disabled={localValues?.mark_as_completed}
                                        name={`mark_as_completed`}
                                        label={"Mark as completed"}
                                        checked={localValues.mark_as_completed}
                                        onChange={(event: any) => setMarkAsCompleted(event?.target?.checked)}
                                    />
                                </div>
                                {markAsCompleted ? <div className="mark_as_completed">
                                    Once marked as completed, you will not be able to open this again.
                                </div> : null}
                                {markAsCompleted ? <div className="title input" style={{ marginLeft: "15px", marginTop: "12px" }}>
                                    <AppFormDatePicker disabled={localValues?.mark_as_completed} requiredSign={true} name={"task_completed_date"} label="Date" />
                                    <AppFormTimePickerMui
                                        requiredSign={true}
                                        disabled={localValues?.mark_as_completed}
                                        name={"task_completed_time"}
                                        label="Time"
                                        utcTime={true}
                                        divStyle={{ marginRight: "21px" }}
                                    />
                                </div> : null}
                            </div>
                        </div>
                        <div className="mark-complete-buttons">
                            <AppButton onClick={props?.onClose} variant="grey">
                                cancel
                            </AppButton>
                            {!localValues?.mark_as_completed ? <AppSubmitButton checkIsValidProperty={false} title={props?.taskId ? "Update" : "Save"} ></AppSubmitButton> : null}
                        </div>
                    </AppForm>
                </div>
            </CreateTaskWapper>
        </Drawer>
    )
}
const CreateTaskWapper = styled.div`
    width: 103%;
    /* padding: 0.5rem 1rem; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    border-radius: 0px 0px 4px 2px;
    min-height: calc(-4rem + 100vh);
    margin: 0px -13px;
    .mark-complete {
        /* padding: 1rem; */
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 100%;
        padding: 15px 13px 0px;
        border-bottom: 1px solid lightgray;
    }
    .mark-complete-footer {
        -webkit-box-pack: justify;
        justify-content: flex-end;
        gap: 9px;
        width: 100%;
        padding: 12px 10px 0px;
        /* margin: 2px; */
        display: flex;
        border-top: 1px solid lightgray;
    }
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
    .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
    }
      .popup-heading-icon svg {
        width: 12px;
        height: 12px;
        color: red;
        margin-right : 15px;
    }
    .create-task-body{
        .left{
            width : 65%;
            border-right : 1px solid lightgray;
            height: calc(-133px + 100vh);
            .title{
                margin: 0px 19px;
                .input{
                    border : 1px soild lightgray !important;
                }
            }
        }
        .right{
            width: 33%;
        }
    }
    .mark-complete-buttons {
        display: flex;
        gap: 16px;
        -webkit-box-pack: end;
        justify-content: flex-end;
        padding: 15px 30px 6px 8px;
        border-top: 1px solid lightgray;
        width: 100%;
        margin-top: -6px;
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
    .date-time-picker {
        display: flex;
        gap: 8px;
        align-items: end;
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            padding: 0;
        }
    }
        .body{
            display: flex;
            margin: 3px 11px;
        }
        .mark_as_completed{
            border: 1px solid lightgray;
            border-radius: 4px;
            padding: 3px 13px;
            margin: 3px 10px;
            background-color: #FFEEC8;
            /* text-align: center; */
            font-size: 13px;
        }
        .starSuperScript{
            top: 0;
            font-size: 16px;
            color: red;
        }
        .contact_info{
            border: 1px solid rgb(203, 212, 255);
            margin: 5px 14px;
            padding: 4px 8px;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            background-color: rgb(245, 246, 248);
            border-radius: 2px;
            width: 96%;
        }
}

`
export default HocBackdropLoader(Task)