import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../socket/hooks/socket-context';
import { useAppSelector } from '../../Store';
import { useAuthStore } from '../../Store/Slices/AuthSlice';

export const NovuNotification = (props:any) => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { data: authData, userConfig } = useAppSelector(useAuthStore);
  const onClick = (data:any) => {
    if(data?.cta?.data?.url){
      navigate(data?.cta?.data?.url)
    }
  }  
  return (
    <NovuProvider backendUrl={process.env.REACT_APP_NOTIFICATION_APPLICATION_BACKEND_URL} subscriberId={`${authData?.data?.user?.workspace_id}:${authData?.data?.user?.id}`} applicationIdentifier={process.env.REACT_APP_NOTIFICATION_APPLICATION_IDENTIFIER?.toString() || ""}>
      <PopoverNotificationCenter showUserPreferences={false} onNotificationClick={onClick} colorScheme={'light'}>
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};