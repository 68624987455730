import { Typography } from "@mui/material";
import { getIn } from "formik";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { withLoader } from "../../../AppComponents/Loader";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { WhatsappActions } from "../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import {
  MediaObj,
  mediaVarible,
} from "../../Broadcast/WhatsApp/Utils/Constants";
import WhatsappBroadcastResolveVaribles from "../../Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { saveGraph } from "../config";
import { whatsAppConfigSchema } from "../validationSchema";
import {
  convertDateTimeToUTC,
  dataToTemplateFormData,
} from "../../Broadcast/WhatsApp/Utils/utils";

const SendWhatsApp = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const [list, setList] = useState<any>([]);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const { showAlert } = useNotification();
  const [formikProps, setFormikProps] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    whatsappTemplateId: null,
    components: {},
    resolved_body_variables: [],
    resolved_header_variables: "",
    limitedTimeOfferDate: new Date(Date.now()),
    limitedTimeOfferTime: new Date(Date.now()),
    resolved_buttons_variables: "",
  });
  const { templateByIdData } = useAppSelector(whatsappDataStore);
  const onChange = (value: any) => {
    setInitialValues({
      ...initialValues,
      whatsappTemplateId: {
        id: value?.id,
        value: value?.name,
        meta_template_id: value?.meta_template_id,
      },
    });
    dispatch(WhatsappActions.getTemplateById(value?.id));
    formRef?.current?.setFieldValue("whatsappTemplateId", value?.id);
    formRef?.current?.setFieldValue("components", value?.components);
    formRef?.current?.setFieldValue(
      "resolved_body_variables",
      templateByIdData?.original_response?.body_text,
    );
  };
  useEffect(() => {
    if (templateByIdData?.id)
      formRef?.current?.setFieldValue(
        "resolved_body_variables",
        templateByIdData?.original_response?.body_text,
      );
  }, [templateByIdData]);
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };

  useEffect(() => {
    if (formRef?.current?.values?.whatsappTemplateId?.id)
      dispatch(
        WhatsappActions.getTemplateById(
          formRef?.current?.values?.whatsappTemplateId?.id,
        ),
      );
  }, [formRef?.current?.values?.whatsappTemplateId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.whatsappTemplateId
    ) {
      setInitialValues({
        ...initialValues,
        whatsappTemplateId: {
          id: list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.whatsappTemplateId,
          )?.id,
          value: list?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.whatsappTemplateId,
          )?.name,
        },
      });
    }
  }, [automationPayload, list, automationReduxData]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchData() {
    const data = {
      ...(await dataToTemplateFormData(templateByIdData?.original_response)),
      components: templateByIdData?.original_response?.components,
    };
    formRef?.current?.setFieldValue("template", data);
  }

  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ChatbotConsoleService.getWhatsappTemplateListings({
      limit: 9999,
      offset: 0,
      status: "APPROVED",
    })
      .then((res: any) => {
        setList(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      MediaObj?.includes(templateByIdData?.original_response?.header_type) &&
      !getIn(formRef?.current?.values, "resolved_header_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_header_variables",
        mediaVarible,
      );
    }

    if (
      templateByIdData?.original_response?.header_text?.length &&
      !getIn(formRef?.current?.values, "resolved_header_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_header_variables",
        templateByIdData?.original_response?.header_text,
      );
    }

    if (
      templateByIdData?.original_response?.body_text?.length &&
      !getIn(formRef?.current?.values, "resolved_body_variables")?.length
    ) {
      formRef?.current?.setFieldValue(
        "resolved_body_variables",
        templateByIdData?.original_response?.body_text,
      );
    }
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: any, submitProps: any) => {
    let payload: any = {
      nodeObject: {
        whatsappTemplateId: values?.whatsappTemplateId?.meta_template_id,
        broadcast_template_id: values?.template?.template_id,
        components: {},
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: `${values?.whatsappTemplateId?.value}`,
    };

    if (values?.resolved_header_variables?.length > 0) {
      payload["nodeObject"]["components"]["header"] = {
        type: values?.header_type,
        value:
          values?.resolved_header_variables?.[0]?.value?.source ||
          values?.resolved_header_variables?.[0]?.value,
        index:
          values?.header_type === "TEXT"
            ? values?.resolved_header_variables?.[0]?.index
            : null,
      };
    }
    if (
      values?.template?.original_response?.button_type === "limited_time_offer"
    ) {
      payload["limited_time_offer"] = {};
      payload["limited_time_offer"]["value"] = convertDateTimeToUTC(
        values?.limitedTimeOfferDate,
        values?.limitedTimeOfferTime,
      );
    }
    if (values?.resolved_body_variables?.length > 0) {
      payload["nodeObject"]["components"]["body"] = {
        value: values?.resolved_body_variables,
      };
    }
    if (values?.resolved_buttons_variables?.length > 0) {
      payload["nodeObject"]["components"]["button"] = {
        value: values?.resolved_buttons_variables,
        type: "dynamic",
      };
    }
    if (values?.resolved_carousel_variables?.length > 0) {
      payload["nodeObject"]["components"]["carousel"] = {
        value: values?.resolved_carousel_variables,
        type: "carousel",
      };
    }
    dispatch(setNodeConfig(payload));
    await saveGraph();
    submitProps.setSubmitting(false);
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };

  return (
    <Container>
      <Typography variant="h6">Send WhatsApp</Typography>
      {list?.length ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={whatsAppConfigSchema}
          innerRef={formRef}
        >
          <ModelFormWrapper>
            <AppReactSelectField
              name={"whatsappTemplateId"}
              options={list || []}
              label="WhatsApp Template"
              displayKey="name"
              valueKey="id"
              placeholder="Select WhatsApp Template"
              onChangeCallbackFn={onChange}
              isSearchable={true}
              isClearable={true}
              hideSelectedOptions={true}
              isRequired={true}
              divStyle={{ background: "none", padding: "0" }}
            />
            <>
              {initialValues?.whatsappTemplateId &&
                templateByIdData?.original_response?.inputValues?.flow && (
                  <div>
                    This Template associated to{" "}
                    <span style={{ fontWeight: "500", fontStyle: "oblique" }}>
                      {" "}
                      {
                        templateByIdData?.original_response?.inputValues
                          ?.flow[0]?.flow_name
                      }
                    </span>{" "}
                    flow
                  </div>
                )}
            </>
            <WhatsappBroadcastResolveVaribles />
            <AppFormObserver setFormikProps={setFormikProps} />
          </ModelFormWrapper>

          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container" style={{ textAlign: "center" }}>
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}
    </Container>
  );
};

export default withLoader(SendWhatsApp);

const Container = styled.div`
  && {
    width: 600px;
    max-height: 700px;
    h6 {
      border-bottom: 1px solid ${theme.palette.default.border};
      margin-bottom: 15px;
      padding: 0 0 0 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
const ModelFormWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  .variablesContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    div:nth-child(2) {
      label {
        display: none;
      }
    }
  }
  .image_container {
    text-align: center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px;
  button:last-child {
    margin-left: 15px;
  }
`;
