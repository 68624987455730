import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../../Customization/Theme";
import { AppToolTip } from "../AppToolTip/AppToolTip";

interface AppBadgeProps {
  status: string;
  message?: string;
}

const LogStatusBadge = ({ status, message }: AppBadgeProps) => {
  const [properties, setProperties] = useState<any>({});
  interface StatusProperties {
    label: string;
    color: string;
    backgroundColor: string;
    borderColor: string;
    toolTip?: string;
    padding?: number | string;
  }
  // Define the interface for the entire statuses object
  interface StatusesObject {
    Successful_log: StatusProperties;
    Error_log: StatusProperties;
  }

  function getStatus(status: string) {
    const statusesObject: StatusesObject = {
      Successful_log: {
        label: "Successful",
        color: `${theme.palette.default.successNew}`,
        padding: 0,
        backgroundColor: `${theme.palette.default.successNewBackground}`,
        borderColor: `1px solid ${theme.palette.default.successNewBorder}`,
        toolTip: message || "Successful",
      },
      Error_log: {
        label: "REJECTED",
        color: `${theme.palette.default.error}`,
        backgroundColor: `${theme.palette.default.errorLighter}`,
        borderColor: `1px solid ${theme.palette.default.error}`,
        toolTip: message || "Rejected",
      },
    };
    return statusesObject[status as keyof StatusesObject];
  }
  // useEffect to set the properties of current status to the badge
  useEffect(() => {
    setProperties(getStatus(status));
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppToolTip
      tooltipProps={{
        title: properties?.toolTip,
        placement: "top",
      }}
      className="chip"
      iconButtonProps={{ disableRipple: true }}
      IconComponent={
        <Chip
          label={properties?.label}
          style={{
            color: properties?.color,
            backgroundColor: properties?.backgroundColor,
            border: properties?.borderColor,
            padding: "8px",
            borderRadius: "0px",
          }}
        />
      }
    />
  );
};

export default LogStatusBadge;
